import type { HistoryItem } from "~/types";

const _useHistory = () => useState<HistoryItem[]>("historyItems", () => []);
const _status = () =>
  useState<"pending" | "success" | "error">(
    "historyItemsStatus",
    () => "pending",
  );
export default function () {
  const historyItems = _useHistory();
  const status = _status();
  // ローカルストレージから値を取得
  function fetch() {
    historyItems.value = getHistory();
  }

  // 配列をJSONにしてローカルストレージに保存
  function setHistory(list: HistoryItem[]) {
    if (isActiveStorage("localStorage")) {
      localStorage.setItem("sekou-history", JSON.stringify(list));
      // ローカルストレージの値を変えたときに値を取得し直す
      fetch();
      return true;
    }
    return false;
  }

  // 履歴を保存
  function addHistory(item: HistoryItem) {
    // localStorageが使えないなら何もしない
    if (!isActiveStorage("localStorage")) return;
    let history = getHistory();
    // 既存の履歴がなければそのまま追加
    if (!history.length) {
      setHistory([item]);
    } else {
      // 重複ページの場合取り除いて入れなおす
      const _h = history.find(
        (e) =>
          (e.sekou_cd && e.sekou_cd == item.sekou_cd) ||
          (e.plan_cd && e.plan_cd == item.plan_cd),
      );
      if (_h) {
        if (_h.plan_cd) {
          history = history.filter((e) => e.plan_cd != _h.plan_cd);
        }
        if (_h.sekou_cd) {
          history = history.filter((e) => e.sekou_cd != _h.sekou_cd);
        }
      }
      // 既存の配列に追加
      history.unshift(item);
      // 10件まで保存
      if (history.length > 10) {
        const _history = history.slice(0, 10);
        setHistory(_history);
      } else {
        setHistory(history);
      }
    }
  }

  // 履歴JSONをローカルストレージから取得して配列に
  function getHistory(): HistoryItem[] {
    status.value = "pending";
    // localstorageが使えないなら空の配列を返す
    if (isActiveStorage("localStorage")) {
      try {
        const json = localStorage.getItem("sekou-history");
        // nullじゃない場合パース
        if (json !== null) {
          status.value = "success";
          return JSON.parse(json);
        }
      } catch (e: any) {
        console.log(e.message);
        status.value = "error";
      }
    }
    status.value = "success";
    return [];
  }
  return {
    addHistory,
    fetch,
    status,
    historyItems,
  };
}
